<template>
  <div v-if="visible" class="modal-wrapper">
    <div class="mask" @click="closeLoginModal"></div>
    <div class="login-wrapper">
      <div v-show="type === 'login'" class="login">
        <div class="title">登录</div>
        <div class="content">
          <div class="form-item">
            <div class="label">手机号</div>
            <div :class="mobileFocus ? 'input focus' : 'input'">
              <img src="https://mktv-in-cdn.mockuai.com/16168327306883859.png" alt="" class="icon">
              <input 
                ref="mobileInput"
                type="text" 
                placeholder="请填写11位数手机号" 
                v-model="mobile"
                @focus="onMobileFocus"
                @blur="onMobileBlur"
              />
            </div>
          </div>
          <div class="form-item">
            <div class="label">验证码</div>
            <div :class="codeFocus ? 'input focus' : 'input'">
              <img src="https://mktv-in-cdn.mockuai.com/16168327254676704.png" alt="" class="icon">
              <input 
                ref="codeInput"
                type="text" 
                placeholder="请输入6位数验证码"
                v-model="code"
                @focus="onCodeFocus"
                @blur="onCodeBlur"
              />
              <span 
                class="send" 
                :class="countdown ? '' : 'pointer'"
                @click="sendCode"
              >
                {{ codeText }}
              </span>
            </div>
          </div>
        </div>
        <div 
          :class="disable ? 'btn disable' : 'btn'"
          @click="onLogin"
        >
          立即登录
        </div>
        <div class="sign-up-btn" @click="goSignup">立即注册</div>
      </div>
      <div v-show="type === 'signup'" class="sign-up">
        <div class="qrcode">
          <img :src="qrcodeSrc" alt="">
        </div>
        <div class="tips">打开微信扫一扫,立即登录</div>
        <div class="back-btn" @click="backLogin">返回登录</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Message } from 'element-ui'
  import util from '@/js/util';
  import { mapMutations } from 'vuex'

  export default {
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      inputClear: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        type: 'login',
        mobile: '',
        code: '',
        countdown: 0,
        lock: false,
        mobileFocus: false,
        codeFocus: false,
        timer: null,
        // qrcodeSrc: 'https://mktv-in-cdn.mockuai.com/16179512372939023.png'
        qrcodeSrc: 'https://mktv-in-cdn.mockuai.com/16182123039448133.png'
      }
    },
    watch: {
      visible(newVal) {
        if (!newVal) {
          this.type = 'login'
        }
      },
    },
    computed: {
      codeText() {
        return this.countdown ? `${this.countdown}s` : '获取验证码';
      },
      disable() {
        return !this.mobile || this.code.length < 6;
      }
    },
    destroyed() {
      this.clearInterval();
    },
    methods: {
      ...mapMutations([
        'login',
        'closeLoginModal'
      ]),
      onMobileFocus() {
        this.mobileFocus = true;
      },
      onMobileBlur() {
        this.mobileFocus = false;
      },
      onCodeFocus() {
        this.codeFocus = true;
      },
      onCodeBlur() {
        this.codeFocus = false;
      },
      sendCode() {
        if (!util.isPhoneAvailable(this.mobile)) {
          return Message.error('请输入正确的手机号');
        }
        if (!this.countdown && !this.lock) {
          this.lock = true;
          window.lib.api.post({
            api: 'ec/c/message/mobile/code/send',
            data: {
              mobile: this.mobile,
              scene: 1,
              type: 104,
            },
            success:() => {
              this.lock = false;
              this.$refs.codeInput.focus();
              this.countdown = 60;
              this.timer = setInterval(() => {
                this.countdown--;
                this.countdown <= 0 && this.clearInterval();
              }, 1000);
            },
            error: (e) => {
              this.lock = false;
              Message.error(e.msg);
            }
          });
        }
      },
      clearInterval() {
        if (this.timer) {
          clearInterval(this.timer);
          this.timer = null;
        }
      },

      clearInput() {
        this.mobile = ''
        this.code = ''
        this.clearInterval();
        this.countdown = 0;
      },

      onLogin() {
        if (this.disable) return;
        if (!util.isPhoneAvailable(this.mobile)) {
          return Message.error('请输入正确的手机号');
        }
        window.lib.api.post({
          api: 'ec/c/login/official/pc/verify_code/login',
          data: {
            mobile: this.mobile,
            code: this.code,
          },
          success: (res) => {
            const { access_token, refresh_token, user_id } = res.data;
            const storage = window.lib.storage;
            storage.set('access_token', access_token);
            storage.set('refresh_token', refresh_token);
            storage.set('user_id', user_id);
            this.login();
            this.$message.success('登录成功')
            let nextUrl = storage.get('nextUrl')
            if (nextUrl) {
              storage.rm('nextUrl');
              this.closeLoginModal();
              this.$router.push(nextUrl);
            } else {
              this.closeLoginModal();
            }
          },
          error: (e) => {
            if (e.code === 30005) {
              Message.error('手机号未注册')
            } else {
              Message.error(e.msg);
            }
          }
        });
      },

      goSignup() {
        this.type = 'signup'
      },

      backLogin() {
        this.type = 'login'
      }
    }
  }
</script>

<style lang="less" scoped>
  .modal-wrapper {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .mask {
      position: fixed;
      z-index: 9;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, .3);
    }
    .login-wrapper {
      box-sizing: border-box;
      position: absolute;
      z-index: 11;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 367px;
      height: 339px;
      padding: 30px 60px 46px;
      border-radius: 9px;
      background-color: #fff;
      .title {
        margin-bottom: 15px;
        text-align: center;
        line-height: 28px;
        font-weight: 400;
        font-size: 20px;
        color: #13203d;
      }
      .form-item {
        margin-bottom: 30px;
        .label {
          line-height: 18px;
          font-size: 13px;
          color: #767e92;
        }
        .input {
          display: flex;
          align-items: center;
          height: 30px;
          border-bottom: 1px solid #efefef;
          &.focus {
            border-bottom: 1px solid rgba(48, 110, 237, 1);
          }
          .icon {
            width: 15px;
            height: 17px;
            margin-right: 14px;
          }
          input {
            flex: 1;
            border: none;
            color: #13203d;
            font-size: 13px;
            &:focus {
              outline: none;
            }
            &::placeholder {
              color: #aaabb6;
              font-size: 13px;
            }
          }
          .send {
            color: #ff6000;
            font-size: 13px;
            &.pointer {
              cursor: pointer;
            }
          }
        }
      }
      .btn {
        width: 100%;
        height: 34px;
        margin-top: 10px;
        line-height: 34px;
        text-align: center;
        background-color: #ff6000;
        color: #fff;
        font-size: 12px;
        cursor: pointer;
        &.disable {
          opacity: 0.5; 
        }
      }
      .sign-up-btn {
        margin-top: 20px;
        text-align: center;
        // color: #007cff;
        color: #666;
        font-size: 12px;
        cursor: pointer;
      }
      .qrcode {
        width: 160px;
        height: 160px;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .tips {
        margin-top: 9px;
        line-height: 20px;
        color: #13203d;
        font-weight: 400;
        font-size: 14px;
        text-align: center;
      }
      .back-btn {
        margin-top: 30px;
        width: 247px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        border-radius: 2px;
        background-color: #ff6000;
        color: #fff;
        font-weight: 500;
        font-size: 12px;
        cursor: pointer;
      }
    }
  }
</style>

