<template>
  <div class="page">
    <header>
      <div class="header-container">
        <a href="/intellectual-property" class="logo-wrapper">
          <img src="https://mktv-in-cdn.mockuai.com/16167531741415302.png" alt="" class="logo">
        </a>
        <div 
          class="login"
          @click="handleLogin"
        >
          {{ isLogin ? '退出登录' : '登录' }}
        </div>
      </div>
    </header>
    <router-view></router-view>
    <login-modal 
      ref="loginModal"
      :visible="loginModalOpen"
    ></login-modal>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import LoginModal from '@/components/LoginModal.vue';

  export default {
    name: 'IntellectualProperty',
    components: {
      LoginModal,
    },

    computed: mapState([
      'isLogin',
      'loginModalOpen',
    ]),
    methods: {
      ...mapMutations([
        'openLoginModal',
        'logout'
      ]),
      handleLogin() {
        if (!this.isLogin) {
          window.lib.storage.rm('nextUrl')
          this.openLoginModal();
        } else {
          // 退出
          window.lib.api.post({
            api: 'wdzg/b/user/logout',
            data: {},
            success: () => {
              this.$message.success('退出成功')
              this.$router.push('/intellectual-property');
              this.$refs.loginModal.clearInput();
              this.logout();
            },
            error: err => {
              this.$message.error(err.msg);
            }
          })
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  header {
    background-color: #fff;
    .header-container {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 1200px;
      height: 102px;
      margin: 0 auto;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color:  #ededed;
        height: 1px;
        border-radius: 5px;
      }
      .logo-wrapper {
        width: 200px;
        height: 45px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .login {
        font-weight: 400;
        font-size: 18px;
        color: #007cff;
        cursor: pointer;
      }
    }
  }
</style>


